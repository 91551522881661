import { env } from "../env";

export const SET_INFO_RESPONSE = "SET_INFO_RESPONSE";
export const SET_JURIS_RESPONSE = "SET_JURIS_RESPONSE";
export const SET_BUTTONS_RESPONSE = "SET_BUTTONS_RESPONSE";

export const SET_DYNO_USE_REGULATIONS_FILTERS =
  "SET_DYNO_USE_REGULATIONS_FILTERS";

export function saveInfoResponse(data) {
  return {
    type: SET_INFO_RESPONSE,
    payload: data
  };
}

export function saveGetJurisdictionDataResponse(data) {
  return {
    type: SET_JURIS_RESPONSE,
    payload: data
  };
}
export function saveButtonsResponse(data) {
  return {
    type: SET_BUTTONS_RESPONSE,
    payload: data
  };
}
export function saveDynoUserRegulationsFilters(data) {
  return {
    type: SET_DYNO_USE_REGULATIONS_FILTERS,
    payload: data
  };
}

// TODO: this method is used with the region and will be unpopulated if no jurisdiction has been selected. Can default to first, or
export function getJurisdictionData() {
  return async (dispatch) => {
    try {
      const response = await fetch(
        env.REACT_APP_URBANFORM_API_URL + "/api/filter/get-juris-contact"
      );
      const data = await response.json();
      dispatch(saveGetJurisdictionDataResponse(data));
    } catch (e) {
      return console.error(e);
    }
  };
}

export function getRegionsData() {
  return async (dispatch) => {
    try {
      const response = await fetch(
        env.REACT_APP_URBANFORM_API_URL + "/api/filter/get-regions"
      );
      const data = await response.json();
      dispatch(saveButtonsResponse(data));
    } catch (e) {
      return console.error(e);
    }
  };
}
