import { ausConfig } from "./ausConfig";
import { laConfig } from "./laConfig";
import { pdxConfig } from "./pdxConfig";
import { sanConfig } from "./sanConfig";
import { seaConfig } from "./seaConfig";
import { yamConfig } from "./yamConfig";

export const CONFIG_MAP = {
  sea: seaConfig,
  san: sanConfig,
  pdx: pdxConfig,
  aus: ausConfig,
  la: laConfig,
  yam: yamConfig,

  // temp
  "sea-vercel": seaConfig,
  "san-vercel": sanConfig,
  "pdx-vercel": pdxConfig,
  "aus-vercel": ausConfig,
  "la-vercel": laConfig,
  "yam-vercel": yamConfig
};

const hostname = window.location.hostname;
const subdomain = hostname.split(".")[0];

const config = CONFIG_MAP[subdomain] ?? laConfig;

export default config as RegionConfig;
