export const yamConfig: RegionConfig = {
  // yam9 on mapbox
  MAPBOX_STYLE_URL_BASIC: "mapbox://styles/qvt282/cm2f21m5m011l01omgjcs8zzv",
  MAPBOX_STYLE_URL_SATELLITE:
    "mapbox://styles/qvt282/cm2f1z9cu002b01pp0uhc9v2w",
  MAPBOX_STYLE_URL_MBENVELOPE:
    "mapbox://styles/qvt282/cm2f21efi011j01omfv773trk",
  MAPBOX_LAYER_NAME: "sea_blv_high_zoom",
  REGION: {
    slug: "yamhill",
    name: "Yamhill Region, OR"
  },
  JURISDICTIONS: [
    // note how there is no "yamhill" jurisdiction
    // TODO this order currently matters, yamhill county first
    { slug: "yamhill_county", name: "Yamhill County" },
    { slug: "amity", name: "Amity" },
    { slug: "carlton", name: "Carlton" },
    { slug: "dundee", name: "Dundee" },
    { slug: "dayton", name: "Dayton" },
    { slug: "lafayette", name: "Lafayette" },
    { slug: "newberg", name: "Newberg" },
    { slug: "willamina", name: "Willamina" },
    { slug: "city_of_yamhill", name: "City of Yamhill" }
  ],
  BBOX: "-123.51417037723357,45.03218731082961,-122.87686705441986,45.3872438649021",
  MAP_CENTER: "-123.19551871582667,45.2099926419165",
  OTHER_REGIONS:
    '{ "portland": [], "seattle": [], "san_diego": [], "austin": [], "yamhill": [] }',
  PROXIMITY:
    "-123.51417037723357,45.03218731082961,-122.87686705441986,45.3872438649021",
  MB_TILES_LIST: "taxlots-fill",
  ZOOM_LEVEL: 10.4
};
