import { posthog } from "posthog-js";

export function initializePosthog() {
  if (
    !window.location.host.includes("127.0.0.1") &&
    !window.location.host.includes("localhost")
  ) {
    posthog.init("phc_6xlOwdrtYxa4y2QoZN9oe61cun1o7uZyScFBu8xIcpP", {
      api_host: "https://us.i.posthog.com",
      person_profiles: "identified_only" // or 'always' to create profiles for anonymous users as well
    });
  }
}
