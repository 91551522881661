import { useEffect, useState } from "react";
import { Button, Form, Image, Input } from "semantic-ui-react";
import "../../css/DefaultDataWindowContent.css";
import ICON_FACE from "../../images/face-id.png";
import ICON_LOGIN from "../../images/login-key.svg";

import { useAlert } from "react-alert";
import { connect, useSelector } from "react-redux";
import {
  setDataWindow,
  setDataWindowContent
} from "../../actions/actions_datawindow";
import { loginUser } from "../../actions/actions_user";
import { SIGNUP_FIELDS } from "../../constants";

const DefaultUserContent = ({ dispatch, loginError, currentContent }) => {
  const alert = useAlert();
  const loggedUser = useSelector((state) => state.user);

  const dataWindow = useSelector((state) => state.dataWindow);

  const { infoDefaultValues, infoNotLoggedIn } = useSelector(
    (state) => state.info
  );
  const [fieldError, setFieldError] = useState({
    email: false,
    password: false
  });
  const { show } = useSelector((state) => state.dataWindow);
  const [hasSignup, setHasSignup] = useState(false);
  const { filterValues } = useSelector((state) => state.filters);

  const [userInfo, setUserInfo] = useState({
    email: "",
    password: ""
  });

  const handleUserInfo = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    let isError = false;
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (e.target.name === "email") {
      pattern.test(e.target.value) ? (isError = false) : (isError = true);
    } else if (e.target.name === "password") {
      e.target.name.trim().length > 0 ? (isError = false) : (isError = true);
    }
    setFieldError({ ...fieldError, [e.target.name]: isError });
  };

  const { email, password } = userInfo;

  useEffect(() => {
    if (loggedUser?.token !== null) {
      dispatch(
        setDataWindow({
          dataWindow: true,
          content: {
            isContact: true,
            contactInfo: {},
            choosePlan: {},
            isPayment: false
          }
        })
      );
    }
  }, [loggedUser?.token]);

  const handleLogin = () => {
    if (!userInfo.email || !userInfo.password) {
      setFieldError({
        email: true,
        password: true
      });
      alert.error("You have entered an invalid email or password.");
    } else if (!fieldError.email && !fieldError.password) {
      if (infoDefaultValues?.identifiers && infoNotLoggedIn === true) {
        const primeAccountNumber =
          infoDefaultValues.summary[0].properties[0].premacuim_no;
        const body = {
          primaccnum: primeAccountNumber
        };
        dispatch(
          loginUser({ ...userInfo }, null, infoNotLoggedIn === true, body)
        );
      } else {dispatch(loginUser({ ...userInfo }));}
    }
    setUserInfo({
      email: "",
      password: ""
    });
  };

  const handleSignup = () => {
    setHasSignup(true);
    dispatch(
      setDataWindow({
        dataWindow: true,
        content: {
          isContact: true,
          contactInfo: SIGNUP_FIELDS,
          choosePlan: {}
        }
      })
    );
    dispatch(
      setDataWindowContent({
        show: { showData: "sign-up", showControl: "second-window-sign-up" }
      })
    );
  };

  useEffect(() => {
    setHasSignup(false);
    setUserInfo({
      email: "",
      password: ""
    });
    setFieldError({
      email: false,
      password: false
    });
  }, [currentContent]);

  const handleForgetPassword = () => {
    dispatch(
      setDataWindow({
        dataWindow: true,
        content: { isContact: true, contactInfo: "", choosePlan: {} }
      })
    );
    dispatch(
      setDataWindowContent({
        show: { showData: "forget-password", showControl: "login" }
      })
    );
  };

  return (
    <div className="default-user">
      <h1>Hello!</h1>
      <div className="d-user-card">
        <div className="d-user-header">
          <h3>LOGIN/SIGNUP</h3>
        </div>
        <div className="d-user-body">
          <Form error onSubmit={handleLogin} className="login-form">
            <div className="login-fields-container">
              <Form.Field error={fieldError.email}>
                <Input
                  size="big"
                  fluid
                  placeholder="email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => handleUserInfo(e)}
                />
              </Form.Field>
              <Form.Field error={fieldError.password}>
                <Input
                  type="password"
                  size="big"
                  fluid
                  placeholder="password"
                  name="password"
                  value={password}
                  onChange={(e) => handleUserInfo(e)}
                />
              </Form.Field>
            </div>
            <div className="btn-login">
              <Button type="submit">
                <Image src={ICON_LOGIN} />
                <span>login</span>
              </Button>
            </div>
          </Form>

          <div className="forgot-text">
            <p onClick={handleForgetPassword}>Forgot Password</p>
          </div>
        </div>
      </div>

      <div className="btn-create-acc">
        <Button onClick={() => handleSignup()}>
          <Image src={ICON_FACE} />
          <span>create Account</span>
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  userToken: state.user.token,
  loginError: state.user.loginError
});

export default connect(mapStateToProps)(DefaultUserContent);
