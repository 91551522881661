import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Pagination, Table } from "semantic-ui-react";
import {
  setDataWindow,
  setDataWindowContent
} from "../../../actions/actions_datawindow";
import {
  applyFilter,
  updateSelectedProperty
} from "../../../actions/actions_filters";
import { infoSearchMapAddress } from "../../../actions/actions_info";
import { setLoader } from "../../../actions/actions_user";

const ResultTable = (data) => {
  const alert = useAlert();
  const { filterValues } = useSelector((state) => state.filters);
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const onPageChange = (e, pageInfo) => {
    const object = {
      filter_json: filterValues,
      page_number: Math.ceil(pageInfo.activePage)
    };
    dispatch(setLoader(true));
    dispatch(applyFilter(object, token));
  };
  const handleSiteInfo = (property) => {
    if (property.primaccnum === " ") {alert.error("Data not found.");}
    else {
      dispatch(setLoader(true));
      dispatch(
        infoSearchMapAddress({
          place_name: property.addr === null ? "NULL" : property.addr
        })
      );
      dispatch(updateSelectedProperty(property));
      dispatch(
        setDataWindow({
          dataWindow: false,
          content: {
            isContact: null,
            contactInfo: {},
            choosePlan: {},
            isPayment: false,
            isFilter: true
          }
        })
      );
      dispatch(
        setDataWindowContent({
          show: { showData: "", showControl: "filters" }
        })
      );
    }
  };
  return (
    <Table basic="very">
      <Table.Body>
        {data?.data && data.data.length !== 0
          ? data.data.map((row) => {
              return (
                <Table.Row
                  className="table-row"
                  onClick={() => {
                    handleSiteInfo(row);
                  }}
                >
                  <Table.Cell>
                    <div className="site-address">
                      {row.addr === null || row.addr.trim() === ""
                        ? "NULL"
                        : row.addr}
                    </div>
                    <div className="site-info">
                      {row.city === null
                        ? "NULL"
                        : row.city + ", " + row.state_name + " "}
                      {row.state === null ? "NULL" : row.state}
                    </div>
                  </Table.Cell>
                </Table.Row>
              );
            })
          : "No result found."}
      </Table.Body>
      {data?.data && data.data.length !== 0 && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <Pagination
                style={{ width: "100%", justifyContent: "space-between" }}
                activePage={data.activePage}
                onPageChange={onPageChange}
                totalPages={Math.ceil(
                  parseInt(data.totalCount.replace(",", "")) / 10
                )}
                boundaryRange={0}
                siblingRange={0}
                prevItem={{ content: <Icon name="angle single left" /> }}
                nextItem={{ content: <Icon name="angle single right" /> }}
                firstItem={{ content: <Icon name="angle double left" /> }}
                lastItem={{ content: <Icon name="angle double right" /> }}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
};

export default ResultTable;
