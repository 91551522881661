import { useSelector } from "react-redux";

const QueriesArray = () => {
  const { filterValues } = useSelector((state) => state.filters);

  //Jurisdiction
  const filteredJurisdiction = filterValues?.jurisdiction[0]?.checkbox?.filter(
    (item) => item.action === true
  );

  //zoning
  const zoningData = filterValues?.zoning.map((zone, index) => {
    const filteredData = zone?.checkbox
      ?.map((item) => ({
        ...item
      }))
      ?.filter((item) => item.action === true);
    const hasActionTrue = filteredData?.some((item) => item.action === true);

    return {
      title: hasActionTrue && ` ${zone.name} is:`,
      value: filteredData?.map((item, index) => (
        <p className="current-query-value" key={index}>
          {item.label}
        </p>
      ))
    };
  });

  //location
  const locationData = filterValues?.location.map((location, index) => {
    const filteredLocData = location?.checkbox
      ?.map((item) => ({
        ...item
      }))
      ?.filter((item) => item.action === true);
    const hasActionTrue = filteredLocData?.some((item) => item.action === true);
    return {
      title: hasActionTrue && ` ${location.title} is:`,
      value: filteredLocData?.map((item, index) => (
        <p className="current-query-value" key={index}>
          {item.label}
        </p>
      ))
    };
  });

  //lot info
  const lotSize = filterValues?.lot_info.map((lot_info, index) => {
    const filteredLotSize = lot_info?.fields
      ?.map((item) => ({
        ...item
      }))
      ?.filter((item) => item.action === true);
    return {
      filteredLotSize
    };
  });

  const lotType = filterValues?.lot_info.map((lot_info, index) => {
    const filteredLotType = lot_info?.checkbox
      ?.map((item) => ({
        ...item
      }))
      ?.filter((item) => item.action === true);
    return {
      filteredLotType
    };
  });

  //existing structures
  const existingStructures = filterValues?.existing_structures.map(
    (existing, index) => {
      const filteredData = existing?.fields
        ?.map((item) => ({
          ...item
        }))
        ?.filter((item) => item.action === true);
      const hasActionTrue = filteredData?.some((item) => item.action === true);

      return {
        title: hasActionTrue && ` ${existing.title} is:`,
        value: filteredData?.map((item, index) => (
          <div className="current-query-lot">
            <p className="current-min-max">{item.labelText}:</p>
            <p className="current-query-value" key={index}>
              {item.value}
            </p>
          </div>
        ))
      };
    }
  );

  //use data
  const useData = filterValues?.use.map((use, index) => {
    const filteredData = use?.checkbox
      ?.map((item) => ({
        ...item
      }))
      ?.filter((item) => item.action === true);
    const hasActionTrue = filteredData?.some((item) => item.action === true);

    return {
      title: hasActionTrue && ` ${use.name} is:`,
      value: filteredData?.map((item, index) => (
        <p className="current-query-value" key={index}>
          {item.name}
        </p>
      ))
    };
  });

  //far
  const farData = filterValues?.far.map((far, index) => {
    const filteredData = far?.fields
      ?.map((item) => ({
        ...item
      }))
      ?.filter((item) => item.action === true);
    const hasActionTrue = filteredData?.some((item) => item.action === true);
    return {
      title: hasActionTrue && ` ${far.title + " " + far.megaTitle} is:`,
      value: filteredData?.map((item, index) => (
        <div className="current-query-lot">
          <p className="current-min-max">{item.labelText}:</p>
          <p className="current-query-value" key={index}>
            {item.value}
          </p>
        </div>
      ))
    };
  });

  //far
  const heightData = filterValues?.height.map((height, index) => {
    const filteredData = height?.fields
      ?.map((item) => ({
        ...item
      }))
      ?.filter((item) => item.action === true);
    const hasActionTrue = filteredData?.some((item) => item.action === true);
    return {
      title: hasActionTrue && ` ${height.title + " " + height.megaTitle} is:`,
      value: filteredData?.map((item, index) => (
        <div className="current-query-lot">
          <p className="current-min-max">{item.labelText}:</p>
          <p className="current-query-value" key={index}>
            {item.value}
          </p>
        </div>
      ))
    };
  });

  //coverage
  const coverageData = filterValues?.coverage.map((coverage, index) => {
    const filteredData = coverage?.fields
      ?.map((item) => ({
        ...item
      }))
      ?.filter((item) => item.action === true);
    const hasActionTrue = filteredData?.some((item) => item.action === true);
    return {
      title:
        hasActionTrue && ` ${coverage.title + " " + coverage.megaTitle} is:`,
      value: filteredData?.map((item, index) => (
        <div className="current-query-lot">
          <p className="current-min-max">{item.labelText}:</p>
          <p className="current-query-value" key={index}>
            {item.value}
          </p>
        </div>
      ))
    };
  });

  //units
  const unitsData = filterValues?.units.map((units, index) => {
    const filteredData = units?.fields
      ?.map((item) => ({
        ...item
      }))
      ?.filter((item) => item.action === true);
    const hasActionTrue = filteredData?.some((item) => item.action === true);
    return {
      title: hasActionTrue && ` ${units.title + " " + units.megaTitle} is:`,
      value: filteredData?.map((item, index) => (
        <div className="current-query-lot">
          <p className="current-min-max">{item.labelText}:</p>
          <p className="current-query-value" key={index}>
            {item.value}
          </p>
        </div>
      ))
    };
  });

  //maxbuild
  const maxBuildData = filterValues?.maxbuild.map((maxBuild, index) => {
    const filteredData = maxBuild?.fields
      ?.map((item) => ({
        ...item
      }))
      ?.filter((item) => item.action === true);
    const hasActionTrue = filteredData?.some((item) => item.action === true);
    return {
      title:
        hasActionTrue && ` ${maxBuild.title + " " + maxBuild.megaTitle} is:`,
      value: filteredData?.map((item, index) => (
        <div className="current-query-lot">
          <p className="current-min-max">{item.labelText}:</p>
          <p className="current-query-value" key={index}>
            {item.value}
          </p>
        </div>
      ))
    };
  });

  //Array for Current Queries//
  const queriesArray = [
    {
      title: "Jurisdiction is:",
      value: filteredJurisdiction?.map((item, index) => (
        <p className="current-query-value" key={index}>
          {item.label}
        </p>
      ))
    },
    ...zoningData,
    ...locationData,
    {
      title: lotSize?.some((lot) =>
        lot?.filteredLotSize?.some((item) => item.action === true)
      )
        ? "Lot Size is:"
        : "",
      value: lotSize?.map((lot) => (
        <div key={lot.index}>
          {lot.filteredLotSize?.map((item, index) => (
            <div className="current-query-lot">
              <p className="current-min-max">{item.labelText}:</p>
              <p className="current-query-value" key={index}>
                {item.value}
              </p>
            </div>
          ))}
        </div>
      ))
    },
    {
      title: lotType?.some((lot) =>
        lot?.filteredLotType?.some((item) => item.action === true)
      )
        ? "Lot Type is:"
        : "",
      value: lotType?.map((lot) => (
        <div key={lot.index}>
          {lot.filteredLotType?.map((item, index) => (
            <p className="current-query-value" key={index}>
              {item.name}
            </p>
          ))}
        </div>
      ))
    },
    ...existingStructures,
    ...useData,
    ...farData,
    ...heightData,
    ...coverageData,
    ...unitsData,
    ...maxBuildData
  ];

  return queriesArray;
};

export default QueriesArray;
