import { useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import {
  UpdateFilter,
  getFiltersData
} from "../../../../actions/actions_filters";
import config from "../../../../config";
import "../../../../css/zone.css";
import { JurisCheckboxUpdate, checkboxUpdate } from "../../../../utils/Utils";

const LSCheckbox = ({ dispatch, info, columns, selectedFilter, style }) => {
  const { show } = useSelector((state) => state.dataWindow);

  const onChangeSelect = (e) => {
    const { name, checked } = e.target;

    const currentFilter = show.showData;
    const UpdatedFilter = {
      name: currentFilter,
      filterData:
        show.showData === "jurisdiction"
          ? JurisCheckboxUpdate(currentFilter, selectedFilter, name, checked)
          : checkboxUpdate(currentFilter, selectedFilter, name, checked)
    };

    // ddd
    if (show.showData === "jurisdiction") {
      if (checked) {
        if (name === config.REGION.slug) {
          // TODO
          //
          localStorage.setItem("regionSlug", config.REGION.slug);
          dispatch(getFiltersData("portland", UpdatedFilter));
          // dispatch(UpdateFilter(UpdatedFilter));
        } else {
          // TODO refactor all this
          // TODO THIS IS BROKWN
          if (config.SUB_REGION) {
            localStorage.setItem("jurisdictionSlug", config.SUB_REGION);
            dispatch(getFiltersData("seattle", UpdatedFilter));
          }
          //  dispatch(UpdateFilter(UpdatedFilter));
        }
      } else {
        dispatch(UpdateFilter(UpdatedFilter));
      }
    } else {
      dispatch(UpdateFilter(UpdatedFilter));
    }
  };
  return (
    <>
      <Grid columns={columns} style={style ?? {}}>
        {info?.checkbox &&
          info.checkbox.map((field) => {
            return (
              <Grid.Column style={{ padding: "7px 0" }}>
                <div className="ui checkbox">
                  <input
                    type={field.type}
                    name={field.name}
                    required={field.required}
                    onChange={onChangeSelect}
                    checked={field.action}
                  />
                  <label>{field.label}</label>
                </div>
              </Grid.Column>
            );
          })}
      </Grid>
    </>
  );
};

export default LSCheckbox;
