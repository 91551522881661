import config from "../config";
import {
  COVERAGE_FILTER,
  EXISTING_STRUCTURE_FILTER,
  FAR_FILTER,
  HEIGHT_FILTER,
  JURIS_FILTER,
  LAND_LOT_SIZE,
  TOTAL,
  UNITS_FILTER
} from "../constants";
import { env } from "../env";
import { setFiltersMaxValues } from "../utils/Utils";
import { setDataWindow, setDataWindowContent } from "./actions_datawindow";
import {
  getJurisdictionData,
  getRegionsData,
  saveDynoUserRegulationsFilters
} from "./actions_dyno_info";
import { setFilterPrimeAccountNumbers } from "./actions_search";
import { setAlertMessage, setLoader, setUserLoginError } from "./actions_user";

export const SET_FILTER_DEFAULT_VALUE = "SET_FILTER_DEFAULT_VALUE";
export const SET_LOCATION_SPECIFCS_VALUES = "SET_LOCATION_SPECIFCS_VALUES";
export const SET_FILTER_CURRENT_VALUE = "SET_FILTER_CURRENT_VALUE";
export const RESET_ALL_CURRENT_FILTERS = "RESET_ALL_CURRENT_FILTERS";
export const SET_FILTER_VALUES = "SET_FILTER_VALUES";
export const UPDATE_FILTER_BY_NAME = "UPDATE_FILTER_BY_NAME";
export const UPDATE_RESULT_FILTER = "UPDATE_RESULT_FILTER";
export const UPDATE_SELECTED_PROPERTY = "UPDATE_SELECTED_PROPERTY";
export const UPDATE_FILTER_PAYMENT = "UPDATE_FILTER_PAYMENT";
export const SET_SAVED_FILTER = "SET_SAVED_FILTER";
export const SET_FILTER_PLAN = "SET_FILTER_PLAN";
export const SET_FILTER = "SET_FILTER";
export const SET_SHOW_FILTER_FOLDER = "SET_SHOW_FILTER_FOLDER";
export const SET_DEFAULT_FILTER = "SET_DEFAULT_FILTER";
export const SET_ZONE_VALUES = "SET_ZONE_VALUES";

// TODO: NEED TO ADD LOT_TYPE | FAR_LOT | FAR_LOT_BONUS LATER

// This is for zone and zone_cat
export function getSelectFilters(type) {
  return async (dispatch) => {
    const response = await fetch(
      env.REACT_APP_URBANFORM_API_URL + "/api/taxlotswzoning/range/" + type
    );
    const data = await response.json();
    const dataToReturn = {
      type: type,
      data: data.values.filter((value) => value !== null)
    };
    dispatch(setFilterDefaultValue(dataToReturn)); // Here we are also passing the TYPE so we can set it dynamically in the reducer
  };
}

// This is for ones where we need max and min values
export function getRangeFilters(type, table) {
  const filters = [];
  let tableType = "taxlotswzoning";

  if (table) {
    tableType = table;
  }

  return async (dispatch) => {
    const response = await fetch(
      env.REACT_APP_URBANFORM_API_URL + `/api/${tableType}/range/` + type
    );
    const data = await response.json();
    data.values.map(
      (value) => value !== null && filters.push(Math.floor(value))
    );
    const sortedFilters = filters.sort((a, b) => a - b);
    let range = [sortedFilters[0], sortedFilters[sortedFilters.length - 1]];
    if (type === "htlim_adjx" || type === "htlim_bonx") {
      range = [sortedFilters[0], 500];
    } else if (type === "umax_adjx_valu") {
      range = [sortedFilters[0], 300];
    } else if (type === "umax_bonx_valu") {
      range = [sortedFilters[0], 200];
    } else if (type === "envp_grnd_lot_area" || type === "envp_grnd_ftpt_max") {
      range = [sortedFilters[0], 200000];
    } else if (type === "envp_bldg_max_area" || type === "envp_bldg_max_res") {
      range = [sortedFilters[0], 1000000];
    }
    const dataToReturn = {
      type: type,
      data: range
    };
    dispatch(setFilterDefaultValue(dataToReturn));
  };
}

export function applyFilter(object, token) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/filter/search`,
        {
          method: "POST",
          body: JSON.stringify(object)
        }
      );
      const data = await response.json();
      if (Object.keys(data).includes("tokenExpired")) {
        window.location.reload(false);
      } else if (Object.keys(data).includes("message")) {
        Object.keys(data).includes("availableIn")
          ? dispatch(
              setUserLoginError(
                "Request limit exceeded. Please try again in " +
                  data.availableIn +
                  " sec!"
              )
            )
          : dispatch(setUserLoginError(data?.message));
        dispatch(setFilterPrimeAccountNumbers(data?.primaccnum ?? []));
        dispatch(updateResult(data));
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: null,
              contactInfo: {},
              choosePlan: {},
              isPayment: false,
              isFilter: true
            }
          })
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "see_result", showControl: "filters" }
          })
        );
      } else {
        dispatch(setFilterPrimeAccountNumbers(data?.primaccnum ?? []));
        dispatch(updateResult(data));
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: null,
              contactInfo: {},
              choosePlan: {},
              isPayment: false,
              isFilter: true
            }
          })
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "see_result", showControl: "filters" }
          })
        );
      }
      dispatch(setLoader(false));
    } catch {
      dispatch(setLoader(false));
    }
  };
}

export function getSaveFilters(user) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/filter/${user}`,
        {
          method: "GET"
        }
      );
      const data = await response.json();
      if (Object.keys(data).includes("tokenExpired")) {
        window.location.reload(false);
      } else {
        dispatch(setSavedFilters(data));
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: true,
              contactInfo: {},
              choosePlan: {},
              isPayment: false
            }
          })
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "saved-filter", showControl: "dashboard" }
          })
        );
        dispatch(setLoader(false));
      }
    } catch {
      dispatch(setLoader(false));
    }
  };
}

export function deleteSaveFilters(id) {
  return async (dispatch) => {
    await fetch(`${env.REACT_APP_URBANFORM_API_URL}/api/filter/${id}`, {
      method: "DELETE"
    });
    dispatch(setLoader(false));
    dispatch(setAlertMessage("Your filter has been deleted successfully"));
  };
}

export function setDeleteFilter(resp) {
  return (dispatch) => {
    dispatch(setSavedFilters(resp));
  };
}

export function saveFilter(object) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/filter/`,
        {
          method: "POST",
          body: JSON.stringify(object)
        }
      );
      const data = await response.json();
      dispatch(setAlertMessage("Filter has been saved."));
      dispatch(setLoader(false));
    } catch {
      dispatch(setLoader(false));
    }
  };
}

export function getFiltersData(name, upd = null) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(getRegionsData());
    dispatch(getJurisdictionData());

    try {
      const [sliderResp, zoneResp, locationResp, useRegulationsResp] =
        await Promise.all([
          fetch(
            `${env.REACT_APP_URBANFORM_API_URL}/api/filter/get-slider-values`
          ).then((res) => res.json()),
          fetch(
            `${env.REACT_APP_URBANFORM_API_URL}/api/filter/get-zone-values`
          ).then((res) => res.json()),
          fetch(
            `${env.REACT_APP_URBANFORM_API_URL}/api/filter/get-location-values`
          ).then((res) => res.json()),
          fetch(
            `${env.REACT_APP_URBANFORM_API_URL}/api/filter/get-use-regulation-values`
          ).then((res) => res.json())
        ]);

      const lotTypes = zoneResp.lot_types?.map((lotType) => ({
        type: "checkbox",
        default: false,
        label: lotType,
        name: lotType,
        action: false,
        column_name: "lot_type"
      }));

      dispatch(saveDynoUserRegulationsFilters(useRegulationsResp));

      const lotTypeFilter = LAND_LOT_SIZE.find(
        (filter) => filter.name === "lot_type"
      );
      if (Array.isArray(lotTypes)) {
        lotTypeFilter.checkbox = [...lotTypes];
      }

      const zoning = [
        {
          type: "checkbox",
          label: "Select all/none",
          name: "zone_cat1",
          checkbox: zoneResp.zone_cat1
        },
        {
          type: "checkbox",
          label: "Select all/none",
          hide: true,
          name: "zone_cat2",
          checkbox: zoneResp.zone_cat2
        },
        {
          column_name: "zone",
          type: "checkbox",
          label: "Select all/none",
          name: "zones",
          checkbox: zoneResp.zones
        }
      ];

      config.JURISDICTIONS.forEach((jurisdiction) => {
        JURIS_FILTER[0].checkbox.push({
          type: "checkbox",
          label: jurisdiction.name,
          name: jurisdiction.name,
          action: false,
          column_name: "juris"
        });
      });

      const filters = setFiltersMaxValues(
        JURIS_FILTER,
        zoning,
        locationResp,
        LAND_LOT_SIZE,
        useRegulationsResp,
        HEIGHT_FILTER,
        FAR_FILTER,
        EXISTING_STRUCTURE_FILTER,
        COVERAGE_FILTER,
        UNITS_FILTER,
        TOTAL,
        sliderResp
      );

      dispatch(setFilterValues(filters));
      dispatch(setDefaultFilter(filters));

      if (upd) {
        dispatch(UpdateFilter(upd));
      }

      dispatch(setLoader(false));
    } catch (e) {
      console.error("Error fetching filters data", e);
      dispatch(setLoader(false));
    }
  };
}

export function setZoneValue(data) {
  return {
    type: SET_ZONE_VALUES,
    payload: data
  };
}
export function setLocationSpecifics(data) {
  return {
    type: SET_LOCATION_SPECIFCS_VALUES,
    payload: data
  };
}
export function setFilterDefaultValue(filtersAndType) {
  return {
    type: SET_FILTER_DEFAULT_VALUE,
    payload: filtersAndType
  };
}

export function setFilterCurrentValue(filterType, filterValue) {
  return {
    type: SET_FILTER_CURRENT_VALUE,
    payload: {
      type: filterType,
      data: filterValue
    }
  };
}

export function resetAllCurrentFilters(data) {
  return {
    type: RESET_ALL_CURRENT_FILTERS,
    payload: data
  };
}

export function setFilterValues(data) {
  return {
    type: SET_FILTER_VALUES,
    payload: data
  };
}

export function UpdateFilter(data) {
  return {
    type: UPDATE_FILTER_BY_NAME,
    payload: data
  };
}

export function updateResult(data) {
  return {
    type: UPDATE_RESULT_FILTER,
    payload: data
  };
}

export function updateSelectedProperty(data) {
  return {
    type: UPDATE_SELECTED_PROPERTY,
    payload: data
  };
}

export function updateFilterPayment(data) {
  return {
    type: UPDATE_FILTER_PAYMENT,
    payload: data
  };
}
export function setFilterPlan(data) {
  return {
    type: SET_FILTER_PLAN,
    payload: data
  };
}
export function setSavedFilters(data) {
  return {
    type: SET_SAVED_FILTER,
    payload: data
  };
}
export function seRunFilter(data) {
  return {
    type: SET_FILTER,
    payload: data
  };
}

export function setShowFilterFolder(data) {
  return {
    type: SET_SHOW_FILTER_FOLDER,
    payload: data
  };
}

export function setDefaultFilter(data) {
  return {
    type: SET_DEFAULT_FILTER,
    payload: data
  };
}
