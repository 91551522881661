import { env } from "../env";
import { infoUserNotLoggedIn } from "./actions_info";

export const SET_TAXLOT = "SET_TAXLOT";
export const SET_GEOJSON = "SET_GEOJSON";
export const SET_TAXLOT_ERROR = "SET_TAXLOT_ERROR";

export function getTaxlotData(primaccnum, lngLat) {
  return async (dispatch) => {
    const response = await fetch(
      env.REACT_APP_URBANFORM_API_URL + "/api/info/" + primaccnum
    );
    const resp = await response.json();
    const token = window.localStorage.getItem("_uft");
    // alert(JSON.stringify(resp));
    if (lngLat && resp.taxLots) {
      resp.taxLots["mapboxCoords"] = lngLat;
    }
    if (resp && !resp.taxLots) {
      dispatch(setTaxlotError("No tax lot data found"));
    }
    dispatch(setTaxlot(resp));
    if (!(token != null && token !== "null"))
      {dispatch(infoUserNotLoggedIn(true));}
  };
}

export function setTaxlot(result) {
  return {
    type: SET_TAXLOT,
    payload: result
  };
}

export function setGeojson(result) {
  return {
    type: SET_GEOJSON,
    payload: result
  };
}

export function setTaxlotError(msg) {
  return {
    type: SET_TAXLOT_ERROR,
    payload: msg
  };
}
