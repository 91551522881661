export const seaConfig: RegionConfig = {
  MAPBOX_STYLE_URL_BASIC: "mapbox://styles/qvt282/clq3f0abc00bo01plh3pj9r3m", //multi-region-style
  MAPBOX_STYLE_URL_SATELLITE:
    "mapbox://styles/qvt282/cls8mnti901f001r43rix1v44",
  MAPBOX_STYLE_URL_MBENVELOPE:
    "mapbox://styles/qvt282/clmqcraac023301nza13c8eyl",
  MAPBOX_LAYER_NAME: "sea_blv_high_zoom",
  REGION: {
    slug: "seattle",
    name: "Seattle, Washington"
  },
  JURISDICTIONS: [
    {
      slug: "seattle",
      name: "Seattle, Washington"
    },
    {
      slug: "bellevue",
      name: "Bellevue, CA",
      layer: "blv_region_9-16"
    }
  ],
  BBOX: "-122.450180,47.513954,-122.086258,47.744403",
  MAP_CENTER: "-122.259636,47.608478",
  OTHER_REGIONS:
    '{ "portland": [], "seattle": [], "san_diego": [], "austin": [] }',
  PROXIMITY: "-122.169857,47.608825",
  MB_TILES_LIST: "taxlots-fill",
  ZOOM_LEVEL: 10.9
};
